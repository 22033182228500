// import React, { useState } from "react";
import "../css/LoginForm.css";
import axios from "axios";
import globalVarijable from "../globalno/globalVarijable.js";
// import "./App.css";

import React, { useState } from "react";

const LedScreen = () => {
  // const globalVarijable = require("../globalno/globalVarijable");
  // Postavljanje globalne varijable
  //let globalVarijable = require("../globalno/globalVarijable.js");
  // Stanje koje prati da li je svetlo uključeno ili isključeno
  // const [isLightOn, setLightOn] = useState(false);
  const [statusLed2, setStatusLed2] = useState("0");
  let statusLed;

  // Funkcija koja se poziva kada se klikne na dugme za paljenje svetla
  const handleSave = async () => {
    // const apiUrl = globalVarijable.putZaAPI;
    // console.log(apiUrl);
    // console.log(apiUrl + "led/citanje/vrati-status");
    const token = localStorage.getItem("token");
    // Promeni stanje svetla
    // setLightOn(!isLightOn);
    statusLed = "0";
    // console.log(globalVarijable.putZaAPI + 'led/citanje/vrati-status');
    // console.log(globalVarijable.putZaAPI + 'led/pisanje/azuriraj-status');
    try {
      const response = await axios.get(
        globalVarijable.putZaAPI + "led/citanje/vrati-status",
        //'https://led.hulioko.com/app-led/led/citanje/vrati-status',
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // const data = response.json();
      const data = response.data;
      // console.log(data);
      // if (!data.ok) {
      //   throw new Error(`HTTP error! Status: ${data.status}`);
      // }
      if (data.status === "0") statusLed = "1";
      // console.log("data.status", data.status);
      // console.log('statusled', statusLed);
      setStatusLed2(statusLed);
      try {
        var dataPost = {
          status: statusLed,
        };
        const response = axios({
          url: globalVarijable.putZaAPI + "led/pisanje/azuriraj-status", //'https://led.hulioko.com/app-led/led/pisanje/azuriraj-status', //
          method: "POST",
          data: dataPost,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        //console.log(response.data);
      } catch (error) {
        console.error("Greška prilikom slanja zahtjeva POST:", error);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      <h2
        style={{
          fontSize: 40,
          color: statusLed2 === "1" ? "orange" : "grey",
          marginLeft: 20,
        }}
      >
        Svjetlo je {statusLed2 === "1" ? "upaljeno" : "ugašeno"}
      </h2>
      <button
        onClick={handleSave}
        style={{
          fontSize: 20,
          color: statusLed2 === "1" ? "blue" : "white",
          backgroundColor: statusLed2 === "1" ? "orange" : "black",
          marginLeft: 20,
        }}
      >
        {statusLed2 === "1" ? "Ugasi svjetlo" : "Upali svjetlo"}
      </button>
    </div>
  );
};

export default LedScreen;
