import "./App.css";
import LoginScreen from "./screens/LoginScreen";
import LedScreen from "./screens/LedScreen";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Ažuriraj importe
// import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        {" "}
        {/* Koristi Routes umesto Switch */}
        <Route path="/LedScreen" element={<LedScreen />} />
        <Route path="/" element={<LoginScreen />} />
      </Routes>
    </Router>

    // <Router>
    //   <div>
    //     <Route path="/" exact component={LoginScreen} />
    //     <Route path="/LedScreen" component={LedScreen} />
    //   </div>
    // </Router>
    // <div className="login-form">
    //   {/* <header className="App-header"></header> */}
    //   <LoginForm />
    // </div>
  );
}

export default App;
