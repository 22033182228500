export const globalVarijable = {
  //  kupiHulioWebStranica: 'https://kupitest.hulioko.com',

  //putZaAPI: "http://localhost/app-led/",
  putZaAPI: "https://led.hulioko.com/app-led/",

  //  hulioWebStranica: 'https://hulioko.com',
  // 'https://kupitest.hulioko.com/app-kupi/'

  // korisnik:  {
  //   id: 0,
  //   naziv: '',
  //   admin: '0'
  // }
};

export default globalVarijable;
