import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import globalVarijable from "../globalno/globalVarijable.js";

// import "./LoginForm.css";
//import "../App.css";

const LoginScreen = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    // console.log(username);
    try {
      const formData = new URLSearchParams();
      formData.append("appImeKorisnika", username); //
      formData.append("appLozinka", password); //

      const response = await fetch(globalVarijable.putZaAPI + "prijava", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formData.toString(),
      });
      // console.log(formData.toString());
      // console.log(response.ok);
      if (response.ok) {
        // Ako je odgovor uspešan, dohvati token iz odgovora
        const data = await response.json();
        // const token = data.token;

        // Ovde možeš dalje obraditi token (čuvati ga u stanju, localStorage, itd.)
        // console.log("Token:", token);

        // Dodaj logiku za preusmeravanje korisnika ili druge akcije nakon uspešne prijave
        switch (data.kod) {
          case "1": {
            setError("");
            localStorage.setItem("token", data.token);
            navigate("/LedScreen");
            // console.log("token", data.token);
            // getKorisnik(data.token);
            //                              console.log(globalVarijable.korisnik);
            // navigation.navigate("Menu");
            //navigation.navigate('SideBar');
            break;
          }
          case "0": {
            // Polja nisu popunjena
            localStorage.removeItem("token");
            setError("Polja-nisu-popunjena"); //'Polja nisu popunjena');
            // console.error('Polja nisu popunjena');
            break;
          }
          case "4": {
            // Račun je zaključan
            localStorage.removeItem("token");
            console.error("Korisnik-zakljucan");
            break;
          }
          case "7": {
            // Korisnik ne postoji
            localStorage.removeItem("token");
            setError("Korisnik-ne-postoji");
            // console.error('Korisnik ne postoji');
            break;
          }
          case "9": {
            // Pogrešna lozinka
            localStorage.removeItem("token");
            setError("Pogresna-lozinka");
            // console.error('Pogrešna lozinka');
            break;
          }
          default: {
            // Neuspješna prijava
            localStorage.removeItem("token");
            setError("Neuspjesna-prijava"); //+ " " + data.kod
            // console.error('Neuspješna prijava');
            break;
          }
        }
      } else {
        // Ako je odgovor neuspešan, prikaži odgovarajuću grešku
        setError("Pogrešno korisničko ime ili lozinka");
      }
    } catch (error) {
      console.error("Greška prilikom prijavljivanja:", error.message);
    }
  };

  return (
    <form onSubmit={handleLogin} className="login-form">
      <label>
        Korisničko ime:
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </label>
      <br />
      <label>
        Lozinka:
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </label>
      <br />
      <button type="submit">Prijavi se</button>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </form>
  );
};

export default LoginScreen;
